import './Space.scss';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import useBreakpoint from 'hooks/useBreakpoint';
import { useEffect, useRef } from 'react';

import { isIOS } from 'utils/utils';

const Space = () => {
  const tlRef = useRef<gsap.core.Timeline>();
  const { isLargeWidth } = useBreakpoint();

  useEffect(() => {
    if (tlRef.current) {
      tlRef.current.kill();
      gsap.set('.space-sec, .pin-spacer, .ellipse', {
        clearProps: 'all',
      });
    }

    if (isLargeWidth) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.space-sec',
          scrub: true,
          pin: true,
          start: 'top top',
          end: '+=3000',
          // onEnterBack: () => {
          //   if (isIOS) {
          //     ScrollTrigger.refresh();
          //   }
          // },
        },
        defaults: {
          ease: 'linear',
          duration: 1,
        },
      });

      tl.fromTo(
        '.ellipse-1',
        { x: 0, y: 0 },
        { x: '40vw', y: '50vh', rotate: -90 },
        0,
      );
      tl.to('.ellipse-1', { x: '80vw', y: '30vh', rotate: -180 }, 1);
      tl.to('.ellipse-1', { x: '40vw', y: '40vh', rotate: -360 }, 2);
      tl.to('.ellipse-1', { x: '65vw', y: '60vh', rotate: -540 }, 3);
      tl.to('.ellipse-1', { x: '55vw', y: '70vh', rotate: -720 + 12.58 }, 4);

      tl.fromTo(
        '.ellipse-2',
        { x: 0, y: 0 },
        { x: '40vw', y: '50vh', rotate: 90 },
        0,
      );
      tl.to('.ellipse-2', { x: '80vw', y: '30vh', rotate: 180 }, 1);
      tl.to('.ellipse-2', { x: '40vw', y: '40vh', rotate: 360 }, 2);
      tl.to('.ellipse-2', { x: '80vw', y: '60vh', rotate: 540 }, 3);
      tl.to('.ellipse-2', { x: '70vw', y: '40vh', rotate: 720 + 12.58 }, 4);

      tl.fromTo(
        '.ellipse-3',
        { x: 0, y: 0 },
        { x: '40vw', y: '50vh', rotate: -45 },
        0,
      );
      tl.to('.ellipse-3', { x: '80vw', y: '30vh', rotate: -90 }, 1);
      tl.to('.ellipse-3', { x: '60vw', y: '40vh', rotate: -135 }, 2);
      tl.to('.ellipse-3', { x: '90vw', y: '20vh', rotate: -270 }, 3);
      tl.to('.ellipse-3', { x: '75vw', y: '25vh', rotate: -360 + 32.86 }, 4);

      tl.fromTo(
        '.ellipse-4',
        { x: 0, y: 0 },
        { x: '40vw', y: '50vh', rotate: 45 },
        0,
      );
      tl.to('.ellipse-4', { x: '40vw', y: '-40vh', rotate: 90 }, 1);
      tl.to('.ellipse-4', { x: '80vw', y: '-20vh', rotate: 135 }, 2);
      tl.to('.ellipse-4', { x: '90vw', y: '0vh', rotate: 270 }, 3);
      tl.to('.ellipse-4', { x: '140vw', y: '0vh', rotate: 540 + 32.86 }, 4);

      tl.fromTo(
        '.ellipse-5',
        { x: 0, y: 0 },
        { x: '40vw', y: '50vh', rotate: -45 },
        0,
      );
      tl.to('.ellipse-5', { x: '40vw', y: '40vh', rotate: -90 }, 1);
      tl.to('.ellipse-5', { x: '80vw', y: '10vh', rotate: -135 }, 2);
      tl.to('.ellipse-5', { x: '40vw', y: '-40vh', rotate: -270 }, 3);
      tl.to('.ellipse-5', { x: '130vw', y: '-40vh', rotate: -540 + 12.58 }, 4);
      tlRef.current = tl;
    } else {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.space-sec',
          scrub: true,
          pin: true,
          start: 'top top',
          end: '+=2000',
          // onEnterBack: () => {
          //   if (isIOS) {
          //     ScrollTrigger.refresh();
          //   }
          // },
        },
        defaults: {
          ease: 'linear',
          duration: 1,
        },
      });

      tl.fromTo(
        '.ellipse-1',
        { x: 0, y: 0 },
        { x: '80vw', y: '20vh', rotate: -90 },
        0,
      );
      tl.to('.ellipse-1', { x: '160vw', y: '40vh', rotate: -180 }, 1);
      tl.to('.ellipse-1', { x: '60vw', y: '25vh', rotate: -360 }, 2);
      tl.to('.ellipse-1', { x: '160vw', y: '20vh', rotate: -540 }, 3);
      tl.to('.ellipse-1', { x: 0, y: 0, rotate: -720 + 12.58 }, 4);

      tl.fromTo(
        '.ellipse-2',
        { x: 0, y: 0 },
        { x: '80vw', y: '20vh', rotate: 90 },
        0,
      );
      tl.to('.ellipse-2', { x: '160vw', y: '40vh', rotate: 180 }, 1);
      tl.to('.ellipse-2', { x: '60vw', y: '25vh', rotate: 360 }, 2);
      tl.to('.ellipse-2', { x: '160vw', y: '20vh', rotate: 540 }, 3);
      tl.to('.ellipse-2', { x: 0, y: 0, rotate: 720 + 12.58 }, 4);

      tl.fromTo(
        '.ellipse-3',
        { x: 0, y: 0 },
        { x: '80vw', y: '20vh', rotate: -45 },
        0,
      );
      tl.to('.ellipse-3', { x: '160vw', y: '40vh', rotate: -90 }, 1);
      tl.to('.ellipse-3', { x: '60vw', y: '25vh', rotate: -135 }, 2);
      tl.to('.ellipse-3', { x: '160vw', y: '20vh', rotate: -270 }, 3);
      tl.to('.ellipse-3', { x: 0, y: 0, rotate: -360 + 32.86 }, 4);
    }
  }, [isLargeWidth]);

  return (
    <section className="space-sec">
      <div className="grid-bg">
        <h2>
          We are
          <br />
          On the
          <br />
          Nerd Space
        </h2>
        <div className="ellipse ellipse-1">
          <div></div>
        </div>
        <div className="ellipse ellipse-2">
          <div></div>
        </div>
        <div className="ellipse ellipse-3">
          <div></div>
        </div>
        <div className="ellipse ellipse-4">
          <div></div>
        </div>
        <div className="ellipse ellipse-5">
          <div></div>
        </div>
      </div>
    </section>
  );
};

export default Space;
