import './Project.scss';

import gsap from 'gsap';
import Flip from 'gsap/Flip';
import ScrollTrigger from 'gsap/ScrollTrigger';
import useBreakpoint from 'hooks/useBreakpoint';
import useSize from 'hooks/useSize';
import { useCallback, useEffect, useRef, useState } from 'react';
import Carousel, { ButtonGroupProps } from 'react-multi-carousel';

import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as More } from 'assets/icons/more.svg';
import Project1Thumbnail from 'assets/project-1.png';
import Project2Thumbnail from 'assets/project-2.png';

import ProjectModal from '../project-modal/ProjectModal';

const responsive = {
  any: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const ButtonGroup = ({ next, previous, carouselState }: ButtonGroupProps) => {
  const { currentSlide, totalItems } = carouselState || {};
  return (
    <>
      <button
        className="carousel-arrow arrow-left"
        onClick={() => previous!()}
        type="button"
        disabled={currentSlide === 0}
      >
        <ChevronLeft />
      </button>
      <button
        className="carousel-arrow arrow-right"
        onClick={() => next!()}
        type="button"
        disabled={currentSlide === totalItems! - 1}
      >
        <ChevronRight />
      </button>
    </>
  );
};

const ProjectItem = ({
  onClick,
  thumbnail,
  ...rest
}: {
  onClick: (item: { ref: HTMLAnchorElement; thumbnail: string }) => void;
  thumbnail: string;
}) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const [windowWidth] = useSize();

  const onMouseMove = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const element = ref.current!;
    const { clientWidth, clientHeight } = element;
    const rect = e.currentTarget.getBoundingClientRect();

    const posX = e.clientX - rect.left - clientWidth / 2;
    const posY = -(e.clientY - rect.top - clientHeight / 2);

    const rotationX = (12 * posY) / (clientHeight / 2);
    const rotationY = (12 * posX) / (clientWidth / 2);

    gsap.to(element, { rotationX, rotationY });
  };

  const onMouseLeave = () => {
    gsap.to(ref.current, { rotationY: 0, rotationX: 0 });
  };

  const onProjectClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClick({ ref: ref.current!, thumbnail });
  };

  return (
    <div className="project-item">
      <p className="project-name">
        <span>2020</span> TangTang
      </p>
      <a
        href="#"
        ref={ref}
        onClick={onProjectClick}
        onMouseMove={windowWidth > 991 ? onMouseMove : undefined}
        onMouseLeave={windowWidth > 991 ? onMouseLeave : undefined}
        {...rest}
      >
        <img src={thumbnail} alt="" loading="lazy" />
        <div className="overlay">
          <More />
        </div>
      </a>
    </div>
  );
};

const Project = () => {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [activeItem, setActiveItem] = useState<{
    ref: HTMLAnchorElement;
    thumbnail: string;
  } | null>(null);
  const [dragging, setDragging] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const tlRef = useRef<gsap.core.Timeline>();
  const { isLargeWidth } = useBreakpoint();

  useEffect(() => {
    if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
      window.addEventListener('scroll', function () {
        setDragging(true);
        clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
          setDragging(false);
        }, 250);
      });
    }
  }, []);

  useEffect(() => {
    if (tlRef.current) {
      tlRef.current.kill();
      gsap.set('.space-sec, .pin-spacer, .ellipse', {
        clearProps: 'all',
      });
    }

    if (isLargeWidth) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.carousel',
          scrub: true,
          end: '+=3000',
        },
      });
      tl.fromTo('.circle', { rotateZ: 0 }, { rotateZ: 1080 });
      tlRef.current = tl;
    } else {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.project-sec',
          scrub: true,
          end: '+=2000',
        },
      });
      tl.fromTo('.circle-1', { rotateZ: 0 }, { rotateZ: 720 });
      tlRef.current = tl;
    }
  }, [isLargeWidth]);

  useEffect(() => {
    if (activeItem) {
      const details = modalRef.current!;
      const detailImage = details.querySelector('.preview')!;

      const onLoad = () => {
        ScrollTrigger.normalizeScroll({ target: '.overlay-inner' });
        Flip.fit(details.querySelector('.overlay-inner'), activeItem.ref, {
          scale: true,
          fitChild: detailImage,
        });

        const state = Flip.getState(details.querySelector('.overlay-inner'));

        gsap.set(details.querySelector('.overlay-inner'), { clearProps: true });
        gsap.set(details.querySelector('.overlay-inner'), {
          visibility: 'visible',
          overflow: 'auto',
        });

        Flip.from(state, {
          duration: 0.5,
          scale: true,
          ease: 'power3.inOut',
        });

        gsap.set(document.body, { overflow: 'hidden' });
        gsap.set(details.querySelector('.modal-content'), {
          height: 'auto',
        });

        gsap.from(details.querySelector('.modal-content'), {
          height: 0,
          duration: 0.5,
          onStart: () => {
            gsap.to(details, {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              duration: 0.2,
            });
          },
          onComplete: () => {
            gsap.set(details.querySelector('.overlay-inner'), {
              overflow: 'auto',
            });
          },
        });
      };

      setTimeout(onLoad, 100);
    }
  }, [activeItem]);

  const modalOpen = (item: { ref: HTMLAnchorElement; thumbnail: string }) => {
    if (dragging) {
      return;
    }
    setIsOpen(true);
    setTimeout(() => setActiveItem(item), 0);
  };

  const modalClose = () => {
    setIsOpen(false);

    const details = modalRef.current!;
    const detailImage = details.querySelector('.preview')!;

    gsap.set(details, { overflow: 'hidden' });

    const state = Flip.getState(details.querySelector('.overlay-inner'));

    Flip.fit(details.querySelector('.overlay-inner'), activeItem!.ref, {
      scale: true,
      fitChild: detailImage,
    });

    gsap.to(details, {
      backgroundColor: 'unset',
      duration: 0.2,
    });

    gsap.to(details.querySelector('.modal-content'), {
      height: 0,
      duration: 0.5,
      onComplete: () => {
        gsap.set(document.body, { overflow: 'auto' });
        setActiveItem(null);
      },
    });

    Flip.from(state, {
      scale: true,
      duration: 0.5,
      ease: 'power3.inOut',
    }).set(details, {
      visibility: 'hidden',
    });
  };

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  return (
    <>
      <section className="project-sec">
        <h2>Project</h2>
        <div className="circle circle-1"></div>
        <div className="circle circle-2"></div>
        <div className="circle circle-3"></div>
        <div className="carousel">
          <Carousel
            responsive={responsive}
            arrows={false}
            customButtonGroup={<ButtonGroup />}
            renderButtonGroupOutside
            infinite
            autoPlay={!isOpen}
            autoPlaySpeed={3000}
            showDots
            renderDotsOutside
            pauseOnHover
            beforeChange={handleBeforeChange}
            afterChange={handleAfterChange}
          >
            <div className="carousel-item">
              <ProjectItem onClick={modalOpen} thumbnail={Project1Thumbnail} />
              <ProjectItem onClick={modalOpen} thumbnail={Project2Thumbnail} />
              <ProjectItem onClick={modalOpen} thumbnail={Project2Thumbnail} />
            </div>
            <div className="carousel-item">
              <ProjectItem onClick={modalOpen} thumbnail={Project1Thumbnail} />
              <ProjectItem onClick={modalOpen} thumbnail={Project2Thumbnail} />
              <ProjectItem onClick={modalOpen} thumbnail={Project2Thumbnail} />
            </div>
          </Carousel>
        </div>
      </section>
      <ProjectModal
        isOpen={isOpen}
        onRequestClose={modalClose}
        {...activeItem}
        ref={modalRef}
      />
    </>
  );
};

export default Project;
