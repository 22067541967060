import './Development.scss';

import gsap from 'gsap';
import { useEffect } from 'react';

const Development = () => {
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.development-sec',
        scrub: true,
      },
      defaults: {
        ease: 'linear',
      },
    });
    tl.fromTo(
      '.development-sec h2 span:first-child, .development-sec h2 span:last-child',
      { x: '-50%' },
      { x: '50%' },
      0,
    );
    tl.fromTo(
      '.development-sec h2 span:nth-child(2)',
      { x: '50%' },
      { x: '-50%' },
      0,
    );
  }, []);

  return (
    <section className="development-sec">
      <div className="top-text">
        <h3>
          픽셀을 현실로 만드는
          <br />
          공상가들
        </h3>
        <p>
          같은 공간속 같은 방향을 향해
          <br />
          나아가는 공동체
          <br />
          무한한 가능성을 창출해 내는
          <br />
          우리는 너드스페이스 입니다.
        </p>
      </div>
      <h2>
        <span>Life===</span>
        <span>Development</span>
        <span>===Nerd</span>
      </h2>
      <p className="bottom-text">
        평범한 일상에 IT가
        <br />
        조화롭게 스며들게 하는것이
        <br />
        우리의 목표 입니다.
      </p>
    </section>
  );
};

export default Development;
