import './index.scss';
import 'react-multi-carousel/lib/styles.css';

import { gsap } from 'gsap';
import Flip from 'gsap/Flip';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactModal from 'react-modal';

import { isIOS } from 'utils/utils';

import App from './App';
import reportWebVitals from './reportWebVitals';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Flip);
gsap.config({
  force3D: true,
});
ScrollTrigger.config({
  ignoreMobileResize: isIOS,
  autoRefreshEvents: isIOS
    ? 'visibilitychange,DOMContentLoaded,load'
    : 'visibilitychange,DOMContentLoaded,load,resize',
});
ReactModal.setAppElement('#root');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
