import './ProjectModal.scss';

import { forwardRef } from 'react';
import ReactModal from 'react-modal';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  thumbnail?: string;
}

const ProjectModal = forwardRef<HTMLDivElement, Props>(
  ({ isOpen, thumbnail, onRequestClose }, ref) => {
    return (
      <ReactModal
        isOpen={isOpen}
        closeTimeoutMS={300}
        overlayClassName="project-modal-overlay"
        className="project-modal"
        onRequestClose={onRequestClose}
        overlayElement={(props, contentElement) => (
          <div {...props}>
            <div className="overlay-inner">{contentElement}</div>
          </div>
        )}
        overlayRef={(node) =>
          ((ref as React.MutableRefObject<HTMLDivElement>).current = node)
        }
      >
        <button className="close-btn" type="button" onClick={onRequestClose}>
          <CloseIcon />
        </button>
        <div className="preview">
          <img src={thumbnail} alt="" loading="lazy" />
        </div>
        <div className="modal-content">
          <div className="modal-content-inner">
            <div className="modal-header">
              <h3>TangTang</h3>
              <span>2020</span>
            </div>
            <p>
              {
                '평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.\n\n평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.'
              }
            </p>
            <div className="modal-content-content">
              <div className="image-1"></div>
              <div className="text-1">
                <h4>TangTang</h4>
                <p>
                  {
                    '평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.\n\n평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.'
                  }
                </p>
              </div>
              <div className="image-2"></div>
              <p className="text-2">
                {
                  '평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.\n\n평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.평범한 일상에 IT가 조화롭게 스며들게 하는것이 우리의 목표 입니다.'
                }
              </p>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  },
);

ProjectModal.displayName = 'ProjectModal';

export default ProjectModal;
