import './Footer.scss';

import Logo from 'assets/logo-footer.svg';

const Footer = () => {
  return (
    <footer className="footer">
      <img src={Logo} alt="Nerd Space" loading="lazy" width={210} height={16} />
      <p>COPYRIGHT ©2020 ALL RIGHTS RESERVED.</p>
    </footer>
  );
};

export default Footer;
