import './Header.scss';

import LogoSmall from 'assets/logo-header-small.svg';
import Logo from 'assets/logo-header.svg';

const Header = () => {
  return (
    <header className="header">
      <h1 aria-label="Nerd Space">
        <img
          className="hidden-sm"
          src={Logo}
          alt="nerd-space"
          width={110}
          height={40}
        />
        <img
          className="hidden-lg"
          src={LogoSmall}
          alt="nerd-space"
          width={376}
          height={30}
        />
      </h1>
    </header>
  );
};

export default Header;
