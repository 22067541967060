import './Skills.scss';

import gsap from 'gsap';
import { useEffect, useRef } from 'react';

import AndroidSDK from 'assets/skills/logo_AndroidSDK.svg';
import AngularJS from 'assets/skills/logo_angularJS.svg';
import CSS3 from 'assets/skills/logo_css3.svg';
import Django from 'assets/skills/logo_Django.svg';
import Docker from 'assets/skills/logo_Docker.svg';
import ES6 from 'assets/skills/logo_ES6.svg';
import Golang from 'assets/skills/logo_Golang.svg';
import HTML5 from 'assets/skills/logo_html5.svg';
import Java from 'assets/skills/logo_Java.svg';
import Kotlin from 'assets/skills/logo_Kotlin.svg';
import Laravel from 'assets/skills/logo_Laravel.svg';
import MariaDB from 'assets/skills/logo_MariaDB.svg';
import MicrosoftSQL from 'assets/skills/logo_MicrosoftSQL.svg';
import MongoDB from 'assets/skills/logo_MongoDB.svg';
import MySQL from 'assets/skills/logo_MySQL.svg';
import NodeJS from 'assets/skills/logo_node.js.svg';
import PHP from 'assets/skills/logo_PHP.svg';
import PostgreSQL from 'assets/skills/logo_PostgreSQL.svg';
import ReactLogo from 'assets/skills/logo_react.svg';
import Redux from 'assets/skills/logo_Redux.svg';
import Spring from 'assets/skills/logo_Spring.svg';
import Swift from 'assets/skills/logo_Swift.svg';
import TypeScript from 'assets/skills/logo_typeScript.svg';
import VueJS from 'assets/skills/logo_vue.js.svg';
import useBreakpoint from 'hooks/useBreakpoint';

const skills = [
  { Logo: NodeJS, label: 'Node.js' },
  { Logo: TypeScript, label: 'TypeScript' },
  { Logo: ReactLogo, label: 'React' },
  { Logo: HTML5, label: 'HTML5' },
  { Logo: AngularJS, label: 'AngularJS' },
  { Logo: VueJS, label: 'Vue.js' },
  { Logo: CSS3, label: 'CSS 3' },
  { Logo: MicrosoftSQL, label: 'Microsoft SQL' },
  { Logo: Laravel, label: 'Laravel' },
  { Logo: AndroidSDK, label: 'Android SDK' },
  { Logo: Docker, label: 'Docker' },
  { Logo: MongoDB, label: 'MongoDB' },
  { Logo: PHP, label: 'PHP' },
  { Logo: PostgreSQL, label: 'PostgreSQL' },
  { Logo: Java, label: 'Java' },
  { Logo: ES6, label: 'ES6' },
  { Logo: Golang, label: 'Golang' },
  { Logo: MariaDB, label: 'MariaDB' },
  { Logo: Swift, label: 'Swift' },
  { Logo: Redux, label: 'Redux' },
  { Logo: MySQL, label: 'MySQL' },
  { Logo: Spring, label: 'Spring' },
  { Logo: Django, label: 'Django' },
  { Logo: Kotlin, label: 'Kotlin' },
];

const Skills = () => {
  const tlRef = useRef<gsap.core.Tween>();
  const { isLargeWidth } = useBreakpoint();

  useEffect(() => {
    if (tlRef.current) {
      tlRef.current.kill();
    }

    if (isLargeWidth) {
      tlRef.current = gsap.fromTo(
        '.skills-sec .texts-content',
        { y: 100, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.5,
          delay: 0.3,
          scrollTrigger: {
            trigger: '.skills-sec .texts-content',
            toggleActions: 'play none none reset',
            start: '-100 bottom',
          },
        },
      );
    } else {
      gsap.set('.skills-sec .texts-content', { clearProps: 'all' });
    }
  }, [isLargeWidth]);

  return (
    <section className="skills-sec">
      <div className="texts-content">
        <h2>Our Skill</h2>
        <p>
          평범한 일상에 IT가
          <br />
          조화롭게 스며들게 하는것이
          <br />
          우리의 목표 입니다.
        </p>
      </div>
      <ul>
        {skills.map(({ Logo, label }) => (
          <li key={label}>
            <img src={Logo} alt="label" width={60} height={60} loading="lazy" />
            <span>{label}</span>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Skills;
