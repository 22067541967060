import { useEffect, useState } from 'react';

const useBreakpoint = () => {
  const [isLargeWidth, setIsLargeWidth] = useState(window.innerWidth > 991);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeWidth(window.innerWidth > 991);
    };
    window.addEventListener('resize', handleResize);
  }, []);

  return { isLargeWidth };
};

export default useBreakpoint;
