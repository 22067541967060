import './App.scss';

import ScrollTrigger from 'gsap/ScrollTrigger';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import Contact from 'components/contact/Contact';
import Cover from 'components/cover/Cover';
import Development from 'components/development/Development';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import Project from 'components/project/Project';
import Skills from 'components/skills/Skills';
import Space from 'components/space/Space';
import { isIOS } from 'utils/utils';

const MEASUREMENT_ID = 'G-ZVZRMES834';
if (process.env.NODE_ENV !== 'development') {
  ReactGA.initialize(MEASUREMENT_ID);
  ReactGA.send('pageview');
}

function App() {
  useEffect(() => {
    if (isIOS)
      window.addEventListener(
        'orientationchange',
        function () {
          ScrollTrigger.refresh();
        },
        false,
      );
  }, []);

  return (
    <div className="App grid-bg">
      <Header />
      <Cover />
      <Space />
      <Development />
      <Skills />
      <Project />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
