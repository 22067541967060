import './Contact.scss';

import classNames from 'classnames';
import gsap from 'gsap';
import useBreakpoint from 'hooks/useBreakpoint';
import Lottie, { AnimationItem } from 'lottie-web';
import {
  ChangeEvent,
  InputHTMLAttributes,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import confettis from 'assets/confettis.json';

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  name: string;
  textarea?: boolean;
}

const TextField = ({ placeholder, name, textarea }: TextFieldProps) => {
  const InputElement = useMemo(() => {
    return (textarea ? 'textarea' : 'input') as any;
  }, []);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {};

  return (
    <>
      <div
        className={classNames('form-group', {
          textarea,
        })}
      >
        <InputElement
          className="text-field"
          name={name}
          type="text"
          autoComplete="off"
          // onChange={onChange}
          placeholder={placeholder}
        />
      </div>
    </>
  );
};

const Contact = () => {
  const tlRef = useRef<gsap.core.Tween>();
  const lottieRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<AnimationItem | null>(null);
  const { isLargeWidth } = useBreakpoint();

  useEffect(() => {
    if (tlRef.current) {
      tlRef.current.kill();
    }

    if (isLargeWidth) {
      tlRef.current = gsap.fromTo(
        '.contact-sec .texts-content',
        { y: 100, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.5,
          delay: 0.3,
          scrollTrigger: {
            trigger: '.contact-sec .texts-content',
            toggleActions: 'play none none reset',
            start: '-100 bottom',
          },
        },
      );
    } else {
      gsap.set('.contact-sec .texts-content', { clearProps: 'all' });
    }
  }, [isLargeWidth]);

  const onSend = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (!animationRef.current) {
      animationRef.current = Lottie.loadAnimation({
        container: lottieRef.current as Element,
        renderer: 'svg',
        animationData: confettis,
        autoplay: false,
        loop: false,
      });
      animationRef.current.goToAndPlay(0);
    } else {
      animationRef.current.goToAndPlay(0);
    }
  };

  return (
    <section className="contact-sec">
      <div className="texts-content">
        <h2>
          Contact<span> us</span>
        </h2>
        <p>
          평범한 일상에 IT가
          <br />
          조화롭게 스며들게 하는것이
          <br />
          우리의 목표 입니다.
        </p>
      </div>
      <form action="">
        <h3>New project</h3>
        <TextField name="name" placeholder="이름을 입력해주세요." />
        <TextField name="email" placeholder="메일 주소를 입력해주세요." />
        <TextField
          textarea
          name="content"
          placeholder="프로젝트 내용, 일정 등 자세한 정보를 입력해주세요."
        />
        <div className="submit-container">
          <a href="#" onClick={onSend}>
            Send!
          </a>
          <div className="confettis" ref={lottieRef}></div>
        </div>
      </form>
    </section>
  );
};

export default Contact;
