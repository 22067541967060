import './Cover.scss';

import gsap from 'gsap';
import { memo, useEffect, useRef } from 'react';

import { ReactComponent as LogoSmall } from 'assets/logo-cover-small.svg';
import { ReactComponent as Logo } from 'assets/logo-cover.svg';
import useBreakpoint from 'hooks/useBreakpoint';

const Cover = () => {
  const tlRef = useRef<gsap.core.Timeline>();
  const { isLargeWidth } = useBreakpoint();

  useEffect(() => {
    if (tlRef.current) {
      tlRef.current.kill();
      gsap.set('.cover-sec svg, .pin-spacer, .cover-sec', {
        clearProps: 'all',
      });
    }

    if (isLargeWidth) {
      const tl0 = gsap.timeline();
      tl0.fromTo(
        '#nerd',
        { x: '13%', opacity: 0 },
        { opacity: 1, duration: 1 },
      );
      tl0.fromTo(
        '#space',
        { x: '-13%', opacity: 0 },
        { opacity: 1, duration: 1 },
      );
      tl0.to('#nerd, #space', { x: 0, duration: 1 });
      tl0.fromTo('#star', { opacity: 0 }, { opacity: 1, duration: 1 });
      tl0.to('#nerd-stroke, #space-stroke, #star-stroke', {
        opacity: 0,
        duration: 0.5,
      });
      tl0.fromTo(
        '#nerd-plain, #space-plain, #star-plain',
        { opacity: 0 },
        { opacity: 1, duration: 0.5 },
        '<=',
      );
      tl0.fromTo('h1', { opacity: 0 }, { opacity: 1, duration: 0.5 }, '<=');

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.cover-sec',
          scrub: true,
          pin: true,
          start: 'top top',
          end: '+=1000',
        },
      });
      tl.fromTo(
        '.cover-sec svg',
        { scale: 1 },
        { scale: 5, x: '25%', duration: 1 },
      );
      tl.to('.cover-sec svg', { opacity: 0, duration: 0.5 }, '>=-0.5');
      tl.to('.space-sec', { opacity: 1, duration: 0.5 }, '<=-0.2');
      tlRef.current = tl;
    } else {
      const tl0 = gsap.timeline();
      tl0.fromTo(
        '#nerd-sm',
        { x: '-22%', opacity: 0 },
        { opacity: 1, duration: 1 },
      );
      tl0.fromTo('#space-sm', { opacity: 0 }, { opacity: 1, duration: 1 });
      tl0.to('#nerd-sm', { x: 0, duration: 1 });
      tl0.fromTo('#star-sm', { opacity: 0 }, { opacity: 1, duration: 1 });
      tl0.to('#nerd-stroke-sm, #space-stroke-sm, #star-stroke-sm', {
        opacity: 0,
        duration: 0.5,
      });
      tl0.fromTo(
        '#nerd-plain-sm, #space-plain-sm, #star-plain-sm',
        { opacity: 0 },
        { opacity: 1, duration: 0.5 },
        '<=',
      );
      tl0.fromTo('h1', { opacity: 0 }, { opacity: 1, duration: 0.5 }, '<=');

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.cover-sec',
          scrub: true,
          // pin: true,
          start: 'top top',
          end: `+=${window.innerHeight / 2}`,
        },
      });
      tl.fromTo(
        '.cover-sec svg',
        { scale: 1 },
        { scale: 5, x: '25%', duration: 1 },
      );
      tl.to('.cover-sec svg', { opacity: 0, duration: 0.5 }, '>=-0.5');
      tlRef.current = tl;
    }
  }, [isLargeWidth]);

  return (
    <section className="cover-sec">
      <div>
        <h2 aria-label="Nerd Space">
          <Logo className="hidden-sm" />
          <LogoSmall className="hidden-lg" />
        </h2>
      </div>
    </section>
  );
};

export default memo(Cover);
